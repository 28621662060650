<head>
    <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
</head>

<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img src="../../assets/media/logos/the_lake_logo_white.png" alt="The Lake home - Living Xperience logo"
                    (click)="seehome()">

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <a (click)="changeRoEn()">EN</a>
                        </div>

                        <div class="header-phone"><span id="header-icon-phone"
                                class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229
                                    999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail"
                                class="material-symbols-outlined">chat_bubble <a
                                    href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal"
                            data-target="#brosuraModal">vezi&nbsp;broșura</button>


                        <!-- Modal Vizionare-->
                        <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog"
                            aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="vizionareModalLabel">Programează o vizionare</h5>
                                    </div>
                                    <div class="modal-body">
                                        <app-vizionare></app-vizionare>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog"
                            aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title" id="brosuraModalLabel">Vezi broșura</h5>
                                    </div>
                                    <div class="modal-body-brosura">
                                        <app-modal-brosura></app-modal-brosura>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span>
                                        </h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.
                                            </p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                            <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="seealege()">ALEGE-ȚI&nbsp;REZIDENȚA</a>
                                <a class="blue-border" (click)="seelocatie()">LOCAȚIE</a>
                                <a class="blue-border" href="https://thelake.ro/galerie/">GALERIE</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seeBlog()">BLOG</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEZVOLTATOR</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="seealege()">ALEGE-ȚI&nbsp;REZIDENȚA</a>
                            <a (click)="seelocatie()">LOCAȚIE</a>
                            <a href="https://thelake.ro/galerie/">GALERIE</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seeBlog()">BLOG</a>
                            <a (click)="seedezvoltator()">DEZVOLTATOR</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
                <div class="mob-page-title">
                    <h1>Media</h1>
                </div>
            </div>

            <div class="header-slider">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner" id="headerSlider-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="../../assets/media/headerSliders/media_4.jpg" alt="">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100 testImg" src="../../assets/media/headerSliders/media_1.jpg"
                                alt="">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../assets/media/headerSliders/media_2.jpg" alt="">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../assets/media/headerSliders/media_3.jpg" alt="">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                    </a>
                </div>
            </div>


            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span><a>programează o
                        vizionare</a>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon"><a>Vezi broșura</a>
                </div>
                
            </div>

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span>
                <h5>programează <br> o vizionare</h5>
            </div>


            <!--xmasPromo
            <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
                src="../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
        </div>-->
        </div>
        <div class="page-title">
            <h1>Media</h1>
        </div>
    </header>

    <div class="mob-header-slider">
        <div class="header-slider-bcground"></div>
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner" id="mobSlider-inner">
                <div class="carousel-item active">
                    <img class="d-block w-100" src="../../assets/media/headerSliders/media_4.jpg" alt="">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="../../assets/media/headerSliders/media_1.jpg" alt="">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="../../assets/media/headerSliders/media_2.jpg" alt="">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="../../assets/media/headerSliders/media_3.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <section class="page-content-wrapper">
        <div class="page-content">
            <h2 id="content-1">Avem o prezență constantă în media</h2>

            <img id="content-2" class="lifestyle-icon" src="../../assets/media/pattern/Artwork29.svg" alt="">

            <p id="content-3">Ne bucură să împărtășim cu voi cele mai noi știri și cele mai interesante informații din
                real estate, economie și investiții smart.</p>

            <div class="division"></div>
        </div>

        <div class="blue-bckg"></div>

    </section>

    <section class="video-tl-mob">
        <div class="tl-bars">
            <div class="circle cirlc1"></div>
            <div class="actualBar"></div>
            <div class="circle"></div>
            <div class="actualBar"></div>
            <div class="circle"></div>
        </div>
        <div class="tl-videos">
            <div class="box">
                <h3>17 Martie 2023</h3>
                <h2>The Lake Home - George Butunoiu vizitează Cartierul Bunelor&nbsp;Maniere</h2>
                <iframe src="https://www.youtube.com/embed/2FPFrILrC6U" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="box">
                <h3>9 Mai 2022</h3>
                <h2>ZF Investiți în România - Sibiu</h2>
                <iframe src="https://www.youtube.com/embed/Q6x3Qnd5AuE" title="YouTube video player" frameborder="0"
                    allow="encrypted-media; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="box lastBox">
                <h3>8 Aprilie 2022</h3>
                <h2>Legile Afacerilor - Raul Țărnaru, Fondator&nbsp;Novarion</h2>
                <iframe src="https://www.youtube.com/embed/imCf_W7rs6c" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>

            <button class="mobMoreVideos" (click)="NewTabYT()">VEZI MAI MULT</button>
        </div>
    </section>

    <section class="video-tl-wrapper">
        <div class="video-tl">
            <div class="leftBar">
                <div class="box">
                    <h3>17 Martie 2023</h3>
                    <h2>The Lake Home - George Butunoiu vizitează Cartierul Bunelor Maniere</h2>
                    <p>S-au trecut în revistă amenajările și dotările speciale, oferite de cel mai original ansamblu rezidențial din Sibiu.</p>
                </div>
                <div class="box">
                    <iframe src="https://www.youtube.com/embed/Q6x3Qnd5AuE" title="YouTube video player" frameborder="0"
                        allow="encrypted-media; autoplay; clipboard-write; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
                <div class="box lastbox">
                    <h3>8 Aprilie 2022</h3>
                    <h2>Legile Afacerilor - Raul Țărnaru, Fondator Novarion</h2>
                    <p>
                        International Property Awards aduce excelența în România. The Lake Home o aduce la Sibiu, ca
                        unul dintre proiectele premiate și viziunea de succes a lui Raul Țărnaru, fondator Novarion.
                    </p>
                </div>
            </div>

            <div class="tl-bars">
                <div class="circle cirlc1"></div>
                <div class="actualBar"></div>
                <div class="circle"></div>
                <div class="actualBar"></div>
                <div class="circle"></div>
                <div class="actualBar lastBar"></div>
            </div>

            <div class="rightBar">
                <div class="box">
                    <iframe src="https://www.youtube.com/embed/2FPFrILrC6U" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
                <div class="box">
                    <h3>9 Mai 2022</h3>
                    <h2>ZF Investiți în România - Sibiu</h2>
                    <p>
                        Cum se implică sistemul bancar pentru a susține economia? Oportunități, Fonduri Europene și
                        PNRR.
                    </p>
                </div>
                <div class="box lastbox">
                    <iframe src="https://www.youtube.com/embed/imCf_W7rs6c" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="more-btn">
            <button class="MoreVideos" (click)="NewTabYT2()">VEZI MAI MULT</button>
        </div>
    </section>

    <section class="articles-slider">
        <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel" data-interval="false">
            <ol class="carousel-indicators" id="articles-indicators">
                <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="3"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="4"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="5"></li>
                <li data-target="#carouselExampleIndicators2" data-slide-to="6"></li>
            </ol>

            <div class="carousel-inner" id="article-inner">
                <div class="article-item carousel-item active">
                    <div class="art-img">
                        <img src="../../assets/media/articles/zf-real-estate.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Ziarul Financiar</h4>
                        <p>Raul Ţărnaru „Am ajuns la peste 1.000 de investitori în proiect prin intermediul campaniilor de crowdfunding“</p>
                        <a (click)="newArticle0()">CITESTE MAI MULT</a>
                    </div>
                </div>

                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/turnul-sfatului-7mil.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Turnul Sfatului</h4>
                        <p>Proiectul Novarion din Sibiu a atras investiții de 7 milioane de euro. Lângă Lacul Binder: 500 de apartamente, spa, sală de sport, grădiniță și piscină</p>
                        <a (click)="newArticle1()">CITESTE MAI MULT</a>
                    </div>
                </div>

                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/photo-the-lake-home.jpg" alt="">
                    </div>
                    <div class="art-text">
                        <h4> Profit.ro </h4>
                        <p>Novarion: Cartierul Bunelor Maniere din Sibiu schimbă paradigma în imobiliarele din România.</p>
                        <a (click)="newArticle2()">CITESTE MAI MULT</a>
                    </div>
                </div>

                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/Ansamblul-Rezidential-The-Lake-Home-Sibiu-Property-INDEX-1-500x372.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4> Property Index </h4>
                        <p> Când dezvoltatorii tineri se apucă de construit blocuri.</p>
                        <a (click)="newArticle3()">CITESTE MAI MULT</a>
                    </div>
                </div>

                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/turnul-sfatului2.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Turnul Sfatului</h4>
                        <p>Novarion, un proiect de regenerare urbană, dezvoltat în jurul singurului Lacului Binder, a atras peste 1.000 de investitori.</p>
                        <a (click)="NewTabTurnul2()">CITESTE MAI MULT</a>
                    </div>
                </div>

                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/ziarul-financiar2.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>ZF Real Estate by storia.ro</h4>
                        <p>Proiectul Novarion din Sibiu a atras până acum investiţii de 7 milioane euro.</p>
                        <a (click)="NewTabZF()">CITESTE MAI MULT</a>
                    </div>
                </div>
                
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/bani-azi.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>TVR Info</h4>
                        <p>Raul Țărnaru, invitat în cadrul emisiunii „Banii, azi”, moderată de Mădălina Chițu.</p>
                        <a (click)="NewTabTVR()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/adevarul.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Adevărul.ro</h4>
                        <p>Turism necultural cu mâncare bună și cu mame frumoase.</p>
                        <a (click)="NewTabAdevarul()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/anuala.ro.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Anuala.ro</h4>
                        <p>Nominalizare la secțiunea „Arhitectură construită / arhitectura locuinței colective”. Cel mai
                            bun client de arhitectură. </p>
                        <a (click)="NewTabAnuala()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/profit.ro.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Profit.ro</h4>
                        <p>Premieră pe piața imobiliară din România. La Sibiu se construiește Cartierul Bunelor Maniere.
                        </p>
                        <a (click)="NewTabProfit()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/forbes.png" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Forbes România</h4>
                        <p>Investiție de 80 milioane de euro în complexul rezidențial The Lake Home din Sibiu.</p>
                        <a (click)="NewTabForbes()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/leaderstalk.webp" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Leaders Talk</h4>
                        <p>Ce citeşte Raul Ţărnaru, fondatorul Novarion.</p>
                        <a (click)="NewTabLeaders()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/turnul-sfatului.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Turnul Sfatului</h4>
                        <p>A fost inaugurat primul imobil din The Lake Home – Cartierul Bunelor Maniere.</p>
                        <a (click)="NewTabTurnul()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/produs-in-ardeal.jpeg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Produs&nbsp;în&nbsp;Ardeal</h4>
                        <p>Meta Estate Trust a investit 500.000 de euro în prima fază a ansamblului rezidențial The Lake
                            Home din Sibiu.</p>
                        <a (click)="NewTabProdus()">CITESTE MAI MULT</a>
                    </div>
                </div>
                <div class="article-item carousel-item">
                    <div class="art-img">
                        <img src="../../assets/media/articles/ziarul-financiar.jpg" alt="">
                    </div>
                    <div class="art-text">
                        <h4>Ziarul Financiar</h4>
                        <p>Raul Ţărnaru, CEO, Novarion: Văd o stabilizare pe piaţa materialelor de construcţii. Sibiul
                            devine un pol imobiliar.</p>
                        <a (click)="NewTabZiarul()">CITESTE MAI MULT</a>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev"
                id="artic-arrow1">
                <span id="article-prev" class="material-symbols-outlined">arrow_circle_left</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next"
                id="artic-arrow2">
                <span id="article-next" class="material-symbols-outlined">arrow_circle_right</span>
            </a>
        </div>
    </section>


    <!-- new slider test
        <section class="articles-slider">
        <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel" data-interval="false">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="3"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="4"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="5"></li>
            </ol>
            <div class="carousel-inner" id="article-inner">
                <div class="article-item carousel-item active">
                  <div class="art-img">
                    <img src="../../assets/media/articles/profit.ro.jpeg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Profit.ro</h4>
                      <p>Premieră pe piața imobiliară din România. La Sibiu se construiește Cartierul Bunelor Maniere.</p>
                      <a (click)="NewTabProfit()">CITESTE MAI MULT</a>
                  </div>
                </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../assets/media/articles/forbes.png" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Forbes România</h4>
                      <p>Investiție de 80 milioane de euro în complexul rezidențial The Lake Home din Sibiu.</p>
                      <a (click)="NewTabForbes()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../assets/media/articles/leaderstalk.webp" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Leaders Talk</h4>
                      <p>Ce citeşte Raul Ţărnaru, fondatorul Novarion.</p>
                      <a (click)="NewTabLeaders()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../assets/media/articles/turnul-sfatului.jpeg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Turnul Sfatului</h4>
                      <p>A fost inaugurat primul imobil din The Lake Home – Cartierul Bunelor Maniere.</p>
                      <a (click)="NewTabTurnul()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../assets/media/articles/produs-in-ardeal.jpeg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Produs&nbsp;în&nbsp;Ardeal</h4>
                      <p>Meta Estate Trust a investit 500.000 de euro în prima fază a ansamblului rezidențial The Lake Home din Sibiu.</p>
                      <a (click)="NewTabProdus()">CITESTE MAI MULT</a>
                  </div>
              </div>
              <div class="article-item carousel-item">
                <div class="art-img">
                    <img src="../../assets/media/articles/ziarul-financiar.jpg" alt="">
                  </div>
                  <div class="art-text">
                      <h4>Ziarul Financiar</h4>
                      <p>Raul Ţărnaru, CEO, Novarion: Văd o stabilizare pe piaţa materialelor de construcţii. Sibiul devine un pol imobiliar.</p>
                      <a (click)="NewTabZiarul()">CITESTE MAI MULT</a>
                  </div>
              </div>
            </div>

            <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
              <span id="article-prev" class="material-symbols-outlined" aria-hidden="true">arrow_circle_left</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
              <span id="article-next" class="material-symbols-outlined" aria-hidden="true">arrow_circle_right</span>
            </a>
          </div>
    </section>-->

    <section class="allpages-bottom">
        <section class="investitia-wrapper" id="INVESTEȘTE">

            <div class="inv-wrapper-wrapper">
                <div class="investitia">
                    <h2>Care este investiția ideală pentru&nbsp;tine</h2>
                    <div class="investitia-blueBar"></div>
                    <p>Investițiile imobiliare inteligente au fost, sunt și vor fi unul dintre cele mai bune moduri de
                        transport al banilor în timp</p>
                </div>
                <div class="calculator">

                    <div class="bigdiv">
                        <h3>Calculator investiție</h3>
                        <p class="suma">Sumă investită</p>
                        <input class="calcone" id="first" min="7000" placeholder="suma în €" type="number">
                        <p class="termen">Termen <br></p>
                        <div class="labelandimput">
                            <div class="labelandimput2">
                                <input type="radio" id="age2" name="age" value="15" checked="checked">
                                <label class="tag" for="age2">18&nbsp;luni</label>
                            </div>
                            <div class="labelandimput2">
                                <input type="radio" id="age3" name="age" value="35">
                                <label class="tag" for="age3">36&nbsp;luni</label>
                            </div>
                            <div class="labelandimput2">
                                <input type="radio" id="age4" name="age" value="55">
                                <label class="tag" for="age4">54&nbsp;luni</label>
                            </div>
                        </div>
                        <button onclick="add()" class="calc-btn">CALCULEAZĂ-ȚI INVESTITIA</button>
                    </div>
                    <div class="rectangle">
                        <p class="procentul">Profit la termen&nbsp;%
                            <input class="firstresult" id="answer3" disabled="disabled" placeholder="_ %">
                        </p>

                        <p class="dobanda">Profit la termen&nbsp;€
                            <input class="firstresult" id="answer2" disabled="disabled" placeholder="_ €">
                        </p>

                        <p class="return">Sumă returnată la termen&nbsp;€
                            <input class="firstresult" id="answer" disabled="disabled" placeholder="_ €">
                        </p>
                    </div>

                </div>
            </div>
        </section>

        <section class="testemonials">
            <h2>Testimoniale</h2>

            <app-testimonials></app-testimonials>

            <div class="bg-img-testm"></div>
        </section>
    </section>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Proiect dezvoltat de:</p>
                <img src="../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo"
                    onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Birou vânzări:</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”>
                    <p>Strada Tractorului, nr. 12- 28,<br>Sibiu, România</p>
                </a>
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Alege-ți rezidența</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">APARTAMENT STUDIO DUBLU</a> <br>
                        <a (click)="footerlinks2()">APARTAMENT CU 2 CAMERE</a><br>
                        <a (click)="footerlinks3()">APARTAMENT CU 3 CAMERE</a><br>
                        <a (click)="footerlinks4()">APARTAMENT CU 5 CAMERE</a><br>

                    </div>
                    <!--<div class="rezidentie2">
                        <a href="">VILA TIP A</a><br>
                        <a href="">VILA TIP B</a><br>
                        <a href="">VILA TIP C</a><br>
                    </div>-->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCAȚIE</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://thelake.ro/galerie/">GALERIE</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEZVOLTATOR</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link"
                        onclick="NewTabIg()">
                    <img src="../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a>
                    </p>
                </div>
            </div>
            <div class="footer3">
                <h4>Te contactăm noi</h4>
                <p>Completează formularul de mai jos pentru a primi în cel mai scurt timp un răspuns din partea echipei
                    de vânzări</p>
                <input type="text" placeholder="Nume" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                <input type="email" placeholder="Email" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                    required>
                <input type="tel" placeholder="Telefon" [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone"
                    required>
                <textarea id="mesaj" placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required><label
                        class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de
                            prelucrare a datelor.</a></label></div>
                <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">TRIMITE MESAJUL</button>
            </div>
        </div>
    </footer>
</body>