
<div *ngIf="pinin">
  <label class="col-sm-2 col-form-label">parola</label>
  <div class="col-sm-2">
      <input type="password" class="form-control" [(ngModel)]="senha"
      placeholder="">
  </div><br>
  
    <button class="btn btn-primary" (click)="adminsenha()">
      acces
  </button>
  </div>
  
  
  
  
  
  <div *ngIf="pingood">


    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">ADMIN</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

          <li class="nav-item">
            <a class="nav-link" (click)="addClick()" data-toggle="modal" data-target="#exampleModal" href="#">Adauga apartament</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" (click)="filtersvizibility()" >{{Vezi}} filtre</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="seeforms()">Vezi intrari forms</a>
          </li>
        </ul>

      </div>
    </nav>
  <div>
    <div class="filtre" *ngIf="veziTrue">
    <p style="margin: 5px;">alege blocul</p>
    
      <select style="margin: 5px;"
      (change)="FilterFn5()"
      [(ngModel)]="BuildingNameFilter" 
      
      >
          <option>C </option>
          <option>B</option>
      </select>
    
    
      <div class="d-flex" style="margin:5px;" >
        <input [(ngModel)]="ApartmentNumFilter" 
        (keyup)="FilterFn5()" placeholder="numarul apartamentului">
      </div>  
    </div>
  
    <br>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ModalTitle}}</h5>
          <button type="button" class="close" 
          data-dismiss="modal" aria-label="Close"
          (click)="closeClick()" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-add-admin [adm]="adm" *ngIf="ActivateAddEditEmpComp">
            </app-add-admin>
        </div>
      </div>
    </div>
  </div>



<table class="table table-striped">
    <thead>
        <tr>
            <th>  

            Nume </th>
            <th>ETAJ</th>
            <th>CAMERE</th>
            <th>NR APARTAMENT</th>
            <th>PRET</th>
            <th>optiuni</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let dataItem of BuildingList">
            <td>{{dataItem.BuildingName}}</td>
            <td>{{dataItem.BuildingFloor}}</td>
            <td>{{dataItem.ApartmentRooms}}</td>
            <td>{{dataItem.ApartmentNumb}}</td>
            <td>{{dataItem.ApartmentPrice}}</td>
            <td>
                <button type="button" class="btn btn-light mr-1"
                data-toggle="modal" data-target="#exampleModal"
(click)="editClick(dataItem)"
data-backdrop="static" data-keyboard="false"
                >
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                </svg>
                </button>
                <button type="button" class="btn btn-light mr-1"
                (click)="deleteClick(dataItem)"
                >
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"/>
                  </svg>
                </button>
            </td>
        </tr>
    </tbody>
</table>

</div>

