import { ThisReceiver } from '@angular/compiler';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener, Renderer2} from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
declare var $: any; // Acest lucru va asigura că TypeScript nu va arunca erori la utilizarea lui $

@Component({
  selector: 'app-floor-plan',
  templateUrl: './floor-plan.component.html',
  styleUrls: ['./floor-plan.component.css']
})
export class FloorPlanComponent implements OnInit {
  /*modal orders*/
  
    /**/ 
    BuildingId!: string;
    Name!: string;
    Phone!: string;
    Email!: string;
    Message!: string;
    FormLocation!: string;
    DateOfGiving!: any;
    BucketDate!: Date;
    checkmark:boolean=false;
    checkmarckCheck!: number;
    checkmark1:boolean=false;
    checkmarckCheck1!: number;
    checkmark2:boolean=false;
    checkmarckCheck2!: number;
    checkmark3:boolean=false;
    checkmarckCheck3!: number;
    BuildingList:any=[];
    acestCamp:boolean=false;
    acestCamp1:boolean=false;
    acestCamp2:boolean=false;
    ApartmentPrice!: string;
    ApartmentRooms!: any;
    adm:any;
    BuildingIdFilter!: string;
  
    BuildingFloor!: string;
    BuildingNameFilter!: string;
    BuildingFloorFilter!: string;
    
    BuildingRoomsFilter!: string;
    BuildingListWithoutFilter:any=[];
  
    ListForFilter:any=[];
    ListForDeactive:any=[];
    checkBuilding!: number;
    hollowApartmentNumb!: string;
    hollowFloor!: string;
    hollowNrOfRooms!: string;
    hollowSurface!: string;
    hollowPhoto!: string;
    hollowBuilding!: string;
  
  
    checkScrollBuilding!: number;
  
    whatApFilter!: number;
    apSlideCounter!: number;
    ApartmentNumb!: string;
  
    hollowPhotoFloor: string = "";
    nevertrue:boolean=false;
  

      
      private mobBxsliderElemsLength = 0 ;
  
      private elemsPerSlide = 1; //DEFAULT VALUE !!! HERE U PUT HOW MANY U WANT TO HAVE DYSPLAYED AT ONCE
  
  
      //$('.mob-tag-slider-controls').css('display', 'flex');
  
      // let mobTagprevArrow = $('.mob-tag-slider-controls #mob-tag-slide-prev');
      // let mobTagnextArrow = $('.mob-tag-slider-controls #mob-tag-slide-next');
  
      elemWidth: any;
      maxMargin: any;
      firstSeven: any;
      lastSeven: any;
      marginLeft: any;
      //count: any;
      numSlides: any;
  
      counter: any;
      clickedRight: any;
      counter1: any;
      clickedRight1: any;
  
  
  
      previousDirection = '';
  
      isComplete = true;
      nav: any;
      link: any;
  
  
      constructor(private service:SharedService, private router: Router, private datePipe: DatePipe,
      private route: ActivatedRoute,
      private sharedService: SharedService,
      private renderer: Renderer2,
      private elementRef: ElementRef,) { }
  
  
  
      @ViewChild('myDiv', {static: true}) myDiv!: ElementRef;
  
      ngOnInit(): void {
      this.buildingFotoB = 'B1_IMG_0548.png';
      let modal
      modal ="modal"
      this.ListForFilter = [1,2,3,4,5]
      this.ListForDeactive = [0,1,2,3,4]
      this.checkmarckCheck = 1;
      this.checkmarckCheck1 = 1;
      this.checkmarckCheck2 = 1;
      this.checkmarckCheck3 = 1;
      this.whatApFilter= 0;
      this.apSlideCounter=0;
      this.BuildingNameFilter="B"
      this.BuildingRoomsFilter="1"
      this.BuildingFloorFilter="0"
      this.refreshBldList();
      document.getElementById("blC-hover-btns")!.style.display = 'none';
      document.getElementById("blB1-hover-btns")!.style.display = '';
  
  
  
      this.checkBuilding = 1;
  
      this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
      this.makeEtajinit()
      this.makeroomsinit()
  
  
       //buton modal festival
  
       if (this.service.invest === true) {

        this.scrollToInvestSection(); // Call the function by adding parentheses
      }
    
    }
    scrollToInvestSection() {
      const investSection = this.elementRef.nativeElement.querySelector('#invest');
      if (investSection) {
        investSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
    
    openModal() {
      // codul necesar pentru a deschide modalul
  
      $('#vizionareModal').modal('show'); // Aceasta este sintaxa jQuery pentru a deschide un modal Bootstrap
  
    }
    openNewModal() {
      ($('#newModal') as any).modal('show'); // jQuery syntax to open the newModal
    }
    
    //modal nou investitii
    @ViewChild('newModal')
    newModal!: ElementRef;
  
    newModalScheduleViewing() {
      this.hideModal(this.newModal);
      this.removeBackdrop();
    }
    
    private removeBackdrop() {
      const backdrops = document.getElementsByClassName('modal-backdrop');
      while (backdrops.length > 0) {
        let backdrop = backdrops[0];
        if (backdrop.parentNode) {
          backdrop.parentNode.removeChild(backdrop);
        }
      }
    }
    
    private hideModal(modalElement: ElementRef) {
      this.renderer.removeClass(modalElement.nativeElement, 'show');
      modalElement.nativeElement.style.display = 'none';
    }
  //modal frstival start modal
    @ViewChild('xmasButton', { static: true }) xmasButton!: ElementRef;
    triggerButtonClick() {
      this.xmasButton.nativeElement.click();
    }
    modalfestival(event: MouseEvent) {
      if (this.isCloseButton(event.target)) {
        return;
      }
      window.open("https://fb.me/e/3D7mAv0YU");
    }
    
    isCloseButton(target: EventTarget | null): boolean {
      if (!target) {
        return false;
      }
    
      if ((target as HTMLElement).classList.contains('close') ||
          (target as HTMLElement).classList.contains('close-icon') ||
          (target as HTMLElement).classList.contains('material-symbols-outlined')) {
        return true;
      }
    
      return false;
    }
    
    
  
  
    @ViewChild('openModalButton', { static: true }) openModalButton!: ElementRef;
    simulateButtonClick() {
      this.openModalButton.nativeElement.click();
    }
    
    /*
    openmodal(){
      document.getElementById("openModalButton")!.click();
      const etaj2=document.querySelectorAll('.nrCamere1 li')
  
    }*/

  
      //--------------------TAGS SLIDER--------------------//
      checkbuttons(){
        if (this.counter >= 3){
          document.getElementById("tagSlideNext")!.style.display = 'none';
          document.getElementById("tagSlideNext1")!.style.display = 'flex';
        }
      }
      checkbuttonsPREV(){
        if (this.counter >= 1){
          document.getElementById("tagSlidePrev1")!.style.display = 'none';
          document.getElementById("tagSlidePrev")!.style.display = 'flex';
        }
      }
      buttonSlideNEXT(){
      this.checkbuttons();
      this.checkbuttonsPREV();
      document.getElementById("tagSectionWrapper")!.style.marginLeft = this.clickedRight*-1 + 'rem';
      this.clickedRight += 50
      this.counter ++
      console.log()
      }
      buttonSlidePREV(){
        document.getElementById("tagSlideNext")!.style.display = 'flex';
        document.getElementById("tagSlideNext1")!.style.display = 'none';
        this.clickedRight -= 100;
        document.getElementById("tagSectionWrapper")!.style.marginLeft = this.clickedRight*-1+ 'rem';
        this.clickedRight += 50;
        this.counter --;
        if (this.counter <= 1){
          document.getElementById("tagSlidePrev1")!.style.display = 'flex';
          document.getElementById("tagSlidePrev")!.style.display = 'none';
        }
      }
      //--------------------MOBILE TIMELINE SLIDER--------------------//
      checkbuttons1(){
        if (this.counter1 >= 5){
          document.getElementById("timeline-next")!.style.display = 'none';
          document.getElementById("timeline-next1")!.style.display = 'flex';
        }
      }
      checkbuttonsPREV1(){
        if (this.counter1 >= 1){
          document.getElementById("timeline-prev1")!.style.display = 'none';
          document.getElementById("timeline-prev")!.style.display = 'flex';
        }
      }
      buttonSlideNEXT1(){
        this.checkbuttons1();
        this.checkbuttonsPREV1();
        document.getElementById("mob-timeline-image-wrapper")!.style.marginLeft = this.clickedRight1*-1 + 'rem';
        this.clickedRight1 += 10
        this.counter1 ++
        }
  
      buttonSlidePREV1(){
          document.getElementById("timeline-next")!.style.display = 'flex';
          document.getElementById("timeline-next1")!.style.display = 'none';
          this.clickedRight1 -= 20;
          document.getElementById("mob-timeline-image-wrapper")!.style.marginLeft = this.clickedRight1*-1+ 'rem';
          this.clickedRight1 += 10
          this.counter1 --;
          if (this.counter1 <= 1){
            document.getElementById("timeline-prev1")!.style.display = 'flex';
            document.getElementById("timeline-prev")!.style.display = 'none';
          }
      }
  
  
    setAllAccordians(index: number): void{
          //----------ALL TAGS ACCORDION----------//
      const mobtagBoxes=document.querySelectorAll('#mobtag1content, #mobtag2content, #mobtag3content, #mobtag4content, #mobtag5content, #mobtag6content, #mobtag7content, #mobtag8content, #tag1content, #tag2content, #tag3content, #tag4content, #tag5content, #tag6content, #tag7content, #tag8content');
      if(mobtagBoxes[index].className == "open"){
        mobtagBoxes[index].className = "";
      }
      else{
        mobtagBoxes[index].className = "open";
      }
    }
  
    resetAccordians(): void{
      const mobtagBoxes=document.querySelectorAll('#mobtag1content, #mobtag2content, #mobtag3content, #mobtag4content, #mobtag5content, #mobtag6content, #mobtag7content, #mobtag8content, #tag1content, #tag2content, #tag3content, #tag4content, #tag5content, #tag6content, #tag7content, #tag8content');
      for(let i=0; i<mobtagBoxes.length; i++){
        mobtagBoxes[i].className = "";
      }
    }
  

  

  //----------LOCAȚIA ACCORDION----------//  
    locatiaAccordion(index: number): void{
  
      const locatiaPanel=document.querySelectorAll('#panel1, #panel2, #panel3, #panel4');
      if(locatiaPanel[index].className == "open"){
        locatiaPanel[index].className = "";
      }
      else{
        locatiaPanel[index].className = "open";
      }
      }
  
      resetLocatiaAccordion(): void{
      const locatiaPanel=document.querySelectorAll('#panel1, #panel2, #panel3, #panel4');
      for(let i=0; i<locatiaPanel.length; i++){
        locatiaPanel[i].className = "";
      }
    }
  
  
    //----------MOBILE HEADER DATABASE ACCORDION----------//  
    headerDbAccordion(index: number): void{
  
      const blocsNames=document.querySelectorAll('.blocs-names');
      if(blocsNames[index].className == "open"){
        blocsNames[index].className = "";
      }
      else{
        blocsNames[index].className = "open";
      }
      }
    
      resetheaderDbAccordion(): void{
      const blocsNames=document.querySelectorAll('.blocs-names');
      for(let i=0; i<blocsNames.length; i++){
        blocsNames[i].className = "";
      }
    }
  /*formular 1*/
    sendFormOne(){
      if(this.checkmark == true){
      this.FormLocation = "programează vizionare"
      this.BucketDate = new Date();
      this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
  
      var val = {BuildingId:this.BuildingId,
        Name:this.Name,
        Phone:this.Phone,
        Email:this.Email,
        Message:this.Message,
        FormLocation:this.FormLocation,
        DateOfGiving:this.DateOfGiving,};
  
      this.service.addform(val).subscribe(res=>{
        (res.toString());
      });
      alert("Mesajul tau a fost trimis")
    } else {
  
      this.acestCamp=true;
    }
  }
    checked(){
      this.checkmarckCheck = this.checkmarckCheck +1;
      if(this.checkmarckCheck % 2 === 0 ){
      this.checkmark = true;
  
      const modalbody=document.querySelector('.modal-body button');
      modalbody!.className = "modal-button";
  
      }
      else{
        this.checkmark = false;
  
      const modalbody=document.querySelector('.modal-body button');
      modalbody!.className = "deactive modal-button";
      }
  
    }
    sendFormOne2(){
      if(this.checkmark1 == true){
        this.FormLocation = "descărca brosura"
        this.BucketDate = new Date();
        this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
          var val = {BuildingId:this.BuildingId,
          Name:this.Name,
          Phone:this.Phone,
          Email:this.Email,
          Message:'A DESCARCAT BROSURA',
          FormLocation:this.FormLocation,
          DateOfGiving:this.DateOfGiving,};
      
        this.service.addform(val).subscribe(res=>{
          (res.toString());
        });
        alert("Mesajul tau a fost trimis")
        window.open("../../assets/pdf/Brochure_2024_RO.pdf")
      } else {
      
        this.acestCamp1=true;
      }
  }
  
    checked2(){
      this.checkmarckCheck1 = this.checkmarckCheck1 +1;
      if(this.checkmarckCheck1 % 2 === 0 ){
      this.checkmark1 = true;
  
      const modalbody=document.querySelector('.modal-body-brosura button');
      modalbody!.className = "modal-button-brosura";
  
      }
      else{
        this.checkmark1 = false;
  
        const modalbody=document.querySelector('.modal-body-brosura button');
        modalbody!.className = "deactive modal-button-brosura";
      }
  
    }
  
    sendFormOne3(){
      if(this.checkmark2 == true){
      this.FormLocation = "te contactam noi"
      this.BucketDate = new Date();
      this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
      var val = {BuildingId:this.BuildingId,
        Name:this.Name,
        Phone:this.Phone,
        Email:this.Email,
        Message:this.Message,
        FormLocation:this.FormLocation,
        DateOfGiving:this.DateOfGiving,};
  
      this.service.addform(val).subscribe(res=>{
        (res.toString());
      });
      alert("Mesajul tau a fost trimis")
    } else {
  
      this.acestCamp2=true;
  
    }
  }
    checked3(){
      this.checkmarckCheck2= this.checkmarckCheck2 +1;
      if(this.checkmarckCheck2 % 2 === 0 ){
      this.checkmark2 = true;
  
      const modalbody=document.querySelector('.footer3 button');
      modalbody!.className = "";
  
      }
      else{
        this.checkmark1 = false;
  
        const modalbody=document.querySelector('.footer3 button');
        modalbody!.className = "deactive";
      }
  
    }
    checked4(){
  
      this.checkmarckCheck3 = this.checkmarckCheck3 +1;
      
      if(this.checkmarckCheck3 % 2 === 0 ){
      this.checkmark3 = true;
      const modalbody=document.querySelector('.modal-body-oferta button');
      modalbody!.className = "modal-button-oferta";
      }
      else{
        this.checkmark3 = false;
        const modalbody=document.querySelector('.modal-body-oferta button');
        modalbody!.className = "deactive modal-button-oferta";
      }
   
    }
  
  /*------------formular OFERTAS------------*/
  openOferta(){
    window.open("https://www.facebook.com/100064165597277/posts/pfbid02NkycefKBWdFZEQXPNBaxK9XJfxLMZ9NieShQ44Y56QFdw1jLVAemNwRS8x7RCLWNl/")
  }
  openyt(){
    window.open("https://www.youtube.com/watch?v=QrLB2qpF0m8&ab_channel=THELAKEHOME-LivingXperience")
  }
  sendFormOferta(){
    if(this.checkmark3 == true){
    this.FormLocation = "Promotie Craciun"
    this.BucketDate = new Date();
    this.DateOfGiving = (this.datePipe.transform(this.BucketDate,"yyyy-MM-dd"));
  
    var val = {BuildingId:this.BuildingId,
      Name:this.Name,
      Phone:this.Phone,
      Email:this.Email,
      Message:this.Message,
      FormLocation:this.FormLocation,
      DateOfGiving:this.DateOfGiving,};
  
    this.service.addform(val).subscribe(res=>{
      (res.toString());
    });
    alert("Mesajul tau a fost trimis")
  } else {
  
    this.acestCamp=true;
  }
  }
  
  
  buildingFotoB!: string;
  SecondPhoto!: string;
  buildingFotoClass!: string;
  SecondPhotoClass!: string;
  
  /*formular 1*/
  buildingcHECHER(){
    if(this.checkBuilding % 2){
      this.BuildingNameFilter ="B"
      
      document.getElementById("blB1-hover-btns")!.style.display = '';
      document.getElementById("blC-hover-btns")!.style.display = 'none';
  
      const fadeOutClass = 'fade-out';
      const fadeInClass = 'fade-in';

      // remove below

      this.buildingFotoB = 'B1_IMG_0548.png';
      /*
      // Set the initial building photo
      this.buildingFotoB = 'hoverSlider_B.png';
      this.buildingFotoClass = fadeInClass;
    
      setTimeout(() => {
        // Add fade-out class to the image
        this.buildingFotoClass = fadeOutClass;
    
        setTimeout(() => {
          // Change the building photo
          this.buildingFotoB = 'B1_IMG_0548.png';
    
          // Add fade-in class to the image
          this.buildingFotoClass = fadeInClass;
        }, 1000);
      }, 2500);*/
    }
    else{
      this.BuildingNameFilter ="B2"
       
      document.getElementById("blC-hover-btns")!.style.display = '';
      document.getElementById("blB1-hover-btns")!.style.display = 'none';
  
  
  
  
        const fadeOutClass = 'fade-out';
        const fadeInClass = 'fade-in';
        this.SecondPhoto = 'hoverSlider_C.png';
        this.SecondPhotoClass = fadeInClass;
        // remove this below
        this.SecondPhoto = 'bloc-B1B2s.jpg';
    /*
        setTimeout(() => {
          // Add fade-out class to the image
          this.SecondPhotoClass = fadeOutClass;
      
          setTimeout(() => {
            // Change the building photo
            this.SecondPhoto = 'bloc-B1B2s.jpg';
      
            // Add fade-in class to the image
            this.SecondPhotoClass = fadeInClass;
          }, 1000);
        }, 2500);*/
      }
  
    this.FilterFn5()
  
  
  }
  /* ------ etaj clafdire si numar camere selectors ------- */
  addNumber(){
    this.checkBuilding++;
    this.buildingcHECHER()
    this.FilterFn5()
    this.refreshBldList()
    this.resetroomball()
    this.resetapartment()
  }
  substractNumber(){
    this.checkBuilding--;
    this.buildingcHECHER()
    this.FilterFn5()
    this.refreshBldList()
    this.resetroomball()
    this.resetapartment()
  }
  
  
  makeballblue(index: number){
    const etaj0=document.querySelectorAll('.choose-etaj li');
  
      etaj0[index].className = "active";
    
  
    const etaj1=document.querySelectorAll('.nrEtaj li');
  
      etaj1[index].className = "active";
    
  

  
  }
  
  makeballnormal(index: number){
    const etaj0=document.querySelectorAll('.choose-etaj li');
    etaj0[index].className = "";
  
    const etaj1=document.querySelectorAll('.nrEtaj li');
    etaj1[index].className = "";
  

  }
  
  FilterFn6(){
      var BuildingNameFilter = this.BuildingNameFilter;
      var BuildingFloorFilter = this.BuildingFloorFilter;
  
      this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
        { BuildingId:
        { toString: () => string; }; BuildingFloor: { toString: () => string; }; 
        BuildingName: { toString: () => string; }  }) =>{
         
  
        return el.BuildingFloor.toString().toLowerCase().includes(
          BuildingFloorFilter.toString().trim().toLowerCase()
        )&&
          el.BuildingName.toString().toUpperCase().includes(
            BuildingNameFilter.toString().trim().toUpperCase()
          ) 
      });
  }
  makeballroomnormal(index: number){
    const etaj0=document.querySelectorAll('.choose-room li');
    if(etaj0[index].className == ""){
      etaj0[index].className = "";
    }
    else{
      etaj0[index].className = "";
    }
  
    const etaj1=document.querySelectorAll('.nrCam li');
    if(etaj1[index].className == ""){
      etaj1[index].className = "";
    }
    else{
      etaj1[index].className = "";
    }
    
  }
  makeballroomgrey(index: number){
    const etaj0=document.querySelectorAll('.choose-room li');
    if(etaj0[index].className == ""){
      etaj0[index].className = "deactive";
    }
    else{
      etaj0[index].className = "deactive";
    }
  
    const etaj1=document.querySelectorAll('.nrCam li');
    if(etaj1[index].className == ""){
      etaj1[index].className = "deactive";
    }
    else{
      etaj1[index].className = "deactive";
    }
  
  }
  resetroomball(){
    this.makeballroomgrey(0);
    this.makeballroomgrey(1);
    this.makeballroomgrey(2);
    this.makeballroomgrey(3);
    this.makeballroomgrey(4);
    this.makeBtnOfSeeDeactivate();
  }
  resetapartmentwhite(index: number){
      const etaj0=document.querySelectorAll('.choose-etaj li');
      etaj0[index].className = " ";
    const etaj1=document.querySelectorAll('.nrEtaj li');
      etaj1[index].className = " ";

  }
  resetapartment(){
    this.resetapartmentwhite(0);
    this.resetapartmentwhite(1);
    this.resetapartmentwhite(2);
    this.resetapartmentwhite(3);
    this.resetapartmentwhite(4);
  }
  makeBtnOfSeeDeactivate(){

    const etaj1=document.querySelector('.choose-bloc button');
    etaj1!.className = "deactive";
    const etaj0=document.querySelector('.mob-flats-plans button');
    etaj0!.className = "deactive";
  }
  
  makeBtnOfSeeActivate(){

    const etaj0=document.querySelector('.choose-bloc button');
    etaj0!.className = "";
  
    const etaj1=document.querySelector('.mob-flats-plans button');
    etaj1!.className = "";
  }
  
  
   // A getter to combine the two paths


// Add this in your component class
showFloor: boolean = true;
// Assuming this is the correct function to update
apartmentFloorImageSelector() {
  const basePath = '../../assets/media/plans-floor/';
  if (this.BuildingNameFilter === "B2") {
    switch (this.BuildingFloorFilter) {
      case "0":
        this.hollowPhotoFloor = `${basePath}B2-eataj_0.png`;
        this.showFloor = false; // Assuming you want to hide all floor details when "0" is selected
        break;
      case "1":
        this.hollowPhotoFloor = `${basePath}B2-eataj_1.png`;
        this.showFloor = true;
        break;
      case "2":
        this.hollowPhotoFloor = `${basePath}B2-eataj_2.png`;
        this.showFloor = true;
        break;
      case "3":
        this.hollowPhotoFloor = `${basePath}B2-eataj_3.png`;
        this.showFloor = true;
        break;
      case "4":
        this.hollowPhotoFloor = `${basePath}B2-eataj_4.png`;
        this.showFloor = true;
        break;
      default:
        this.showFloor = true;
        break;
    }
  }
  // Implementation for other building names if necessary
}



  makeEtajParter(){

    this.apSlideCounter=0;
    this.BuildingFloorFilter="0";
    this.FilterFn6();
    this.apartmentFloorImageSelector()
    this.resetroomball();
    let lenght1 = (this.BuildingList.length)
  
    for ( let step = 0; step<lenght1; step++){
      for (let num of this.ListForFilter) {
        if(this.BuildingList[step]["ApartmentRooms"] == num){
          this.makeballroomnormal(num-1)
        }}
    }
  
    this.refreshBldList();
    this.makeballblue(0);
    this.makeballnormal(1);
    this.makeballnormal(2);
    this.makeballnormal(3);
    this.makeballnormal(4);
    this.deactiveslider();

  }
  makeEtajinit(){
    this.apSlideCounter=0;
    this.BuildingFloorFilter="1"
    this.FilterFn6();
    this.resetroomball()
    let lenght1 = (this.BuildingList.length)
  
    for ( let step = 0; step<lenght1; step++){
      for (let num of this.ListForFilter) {
        if(this.BuildingList[step]["ApartmentRooms"] == num){
          this.makeballroomnormal(num-1)
        }}
    }
  
  }
  
  makeEtaj1(){ 

    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingFloorFilter="1"

    this.FilterFn6();
    this.apartmentFloorImageSelector()
    this.resetroomball()
    let lenght1 = (this.BuildingList.length)
  
    for ( let step = 0; step<lenght1; step++){
      for (let num of this.ListForFilter) {
        if(this.BuildingList[step]["ApartmentRooms"] == num){
          this.makeballroomnormal(num-1)
        }}
    }
    this.refreshBldList()
  
    this.makeballblue(1)
    this.makeballnormal(0)
    this.makeballnormal(2)
    this.makeballnormal(3)
    this.makeballnormal(4)
    this.deactiveslider();
  
  }
  makeEtaj2(){

    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingFloorFilter="2"
    this.FilterFn6();
    this.apartmentFloorImageSelector()
    this.resetroomball()
    let lenght1 = (this.BuildingList.length)
  
    for ( let step = 0; step<lenght1; step++){
      for (let num of this.ListForFilter) {
        if(this.BuildingList[step]["ApartmentRooms"] == num){
          this.makeballroomnormal(num-1)
        }}
    }
    this.refreshBldList()
  
    this.makeballblue(2)
    this.makeballnormal(1)
    this.makeballnormal(0)
    this.makeballnormal(3)
    this.makeballnormal(4)
    this.deactiveslider();
  
  
  }
  makeEtaj3(){

    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingFloorFilter="3"
    
    this.FilterFn6();
    this.apartmentFloorImageSelector()
    this.resetroomball()
    let lenght1 = (this.BuildingList.length)
  
    for ( let step = 0; step<lenght1; step++){
      for (let num of this.ListForFilter) {
        if(this.BuildingList[step]["ApartmentRooms"] == num){
          this.makeballroomnormal(num-1)
        }}
    }
    this.refreshBldList()
  
    this.makeballblue(3)
    this.makeballnormal(1)
    this.makeballnormal(2)
    this.makeballnormal(0)
    this.makeballnormal(4)
    this.deactiveslider();
  
  }
  
  makeEtaj4(){
    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingFloorFilter="4"
    this.FilterFn6();
    this.apartmentFloorImageSelector()
    this.resetroomball()
    let lenght1 = (this.BuildingList.length)
  
    for ( let step = 0; step<lenght1; step++){
      for (let num of this.ListForFilter) {
        if(this.BuildingList[step]["ApartmentRooms"] == num){
          this.makeballroomnormal(num-1)
        }}
    }
    this.refreshBldList()
  
    this.makeballblue(4)
    this.makeballnormal(1)
    this.makeballnormal(2)
    this.makeballnormal(3)
    this.makeballnormal(0)
    this.deactiveslider();
  
  }
  
  
  makeballblue1(index: number){
    this.makeroomnormal()
    const etaj0=document.querySelectorAll('.choose-room li');
      etaj0[index].className = "active";
    const etaj1=document.querySelectorAll('.nrCam li');
      etaj1[index].className = "active";
  
  }
  
  makeballnormal1(index: number){
    const etaj0=document.querySelectorAll('.choose-room li');
    etaj0[index].className = "";
  
    const etaj1=document.querySelectorAll('.nrCam li');
    etaj1[index].className = "";

  }
  
  
  makeballnormal2(index: number){
    const etaj1=document.querySelectorAll('.nrCam li');
    etaj1[index].className = "";
  }
  checkballfromactivetonormal(index: number){
    const etaj1=document.querySelectorAll('.choose-room  li');
    if(etaj1[index].className == "active"){
      etaj1[index].className = "";
    }
    const etaj0=document.querySelectorAll('.nrCam li');
    if(etaj0[index].className == "active"){
      etaj0[index].className = "";
    }
  }
  makeroomnormal(){
  this.checkballfromactivetonormal(0);
  this.checkballfromactivetonormal(1);
  this.checkballfromactivetonormal(2);
  this.checkballfromactivetonormal(3);
  this.checkballfromactivetonormal(4);
  }
  deactiveslider(){
    document.getElementById("mob-plans-next")!.style.display = 'none';
    document.getElementById("mob-plans-next1")!.style.display = 'flex';
  
    document.getElementById("plans-next")!.style.display = 'none';
    document.getElementById("plans-next1")!.style.display = 'flex';
  
    document.getElementById("mob-plans-prev")!.style.display = 'none';
    document.getElementById("mob-plans-prev1")!.style.display = 'flex';
  
    document.getElementById("plans-prev")!.style.display = 'none';
    document.getElementById("plans-prev1")!.style.display = 'flex';
  }
  
  checkLenghList(){
    if(this.BuildingList.length == 1){
      this.deactiveslider()
    }
    else{
      document.getElementById("mob-plans-next")!.style.display = 'flex';
      document.getElementById("mob-plans-next1")!.style.display = 'none';
    
      document.getElementById("plans-next")!.style.display = 'flex';
      document.getElementById("plans-next1")!.style.display = 'none';
      
      document.getElementById("mob-plans-prev")!.style.display = 'none';
      document.getElementById("mob-plans-prev1")!.style.display = 'flex';
    
      document.getElementById("plans-prev")!.style.display = 'none';
      document.getElementById("plans-prev1")!.style.display = 'flex';
    }
  
  }
  makeroomsinit(){
    this.apSlideCounter=0;
    this.BuildingRoomsFilter="1"
    this.FilterFn5()
    this.refreshBldList()
    this.makeBtnOfSeeActivate();
    this.checkLenghList()
  
  }
  apartment(item: any){

    this.service.selectedApartment=this.BuildingList[this.whatApFilter]
    this.numarulapartamentuluiselectat = this.BuildingList[this.whatApFilter].ApartmentNumb
    this.modaldetalii= true;  
  
    this.service.serviceRoDetalii=true;
    this.service.serviceEnDetalii=false;
  }
  makerooms1(){
    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingRoomsFilter="1"
    this.FilterFn5()
    this.refreshBldList()
    this.makeBtnOfSeeActivate();
    this.makeballblue1(0)
    this.checkLenghList()
  
  }
  makerooms2(){
    this.whatApFilter=0;
    this.apSlideCounter=0;
  
    this.BuildingRoomsFilter="2"
    this.FilterFn5()
    this.refreshBldList()
    this.makeBtnOfSeeActivate();
    this.makeballblue1(1)
    this.checkLenghList()
  }
  makerooms3(){
    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingRoomsFilter="3"
    this.FilterFn5()
    this.refreshBldList()
    this.makeBtnOfSeeActivate();
    this.makeballblue1(2)
    this.checkLenghList()
  
  }
  makerooms4(){
    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingRoomsFilter="4"
    this.FilterFn5()
    this.refreshBldList()
    this.makeBtnOfSeeActivate();
    this.makeballblue1(3)
    this.checkLenghList()
  
  }
  makerooms5(){
    this.whatApFilter=0;
    this.apSlideCounter=0;
    this.BuildingRoomsFilter="5"
    this.FilterFn5()
    this.refreshBldList()
    this.makeBtnOfSeeActivate();
    this.makeballblue1(4)
    this.checkLenghList()
  
  }
  // Modify this function to accept apartment number and building name
  selected(item: any){
  
    this.adm=item;
    this.ApartmentPrice == this.adm.ApartmentPrice
    this.hollowApartmentNumb = "nr. " + this.adm.ApartmentNumb
    this.hollowFloor="etaj " + this.adm.BuildingFloor
    this.hollowNrOfRooms= this.adm.ApartmentRooms + " camere"
    this.hollowSurface =  this.adm.ApartmentSurface+" M²"
    this.hollowPhoto =  "../../assets/" + this.adm.BuildingPhoto
    this.hollowBuilding = this.adm.BuildingName
    
    if(this.adm.BuildingFloor == "0"){
      this.hollowFloor = "parter"
    }
  
    if(this.adm.ApartmentPrice == 0){
      this.hollowApartmentNumb = "indisponibil"
      this.hollowFloor=" indisponibil"
      this.hollowNrOfRooms= " indisponibil"
      this.hollowSurface = " indisponibil"
      this.hollowPhoto =  "../../assets/" + this.adm.BuildingPhoto
      this.hollowBuilding = " indisponibil"
    }
  }

  
  refreshBldList(){
    this.service.getBuildingList().subscribe(data=>{
      this.BuildingList=data;
      this.BuildingListWithoutFilter=data;
      this.FilterFn5(); 

      this.checkScrollBuilding=(this.BuildingList.length)
  
      this.service.selectedApartment=this.BuildingList[this.whatApFilter]
  
  
    });
  }
  
  numarulapartamentuluiselectat!: string;
  modaldetalii:boolean=false;
  seeApartment(){
  
    this.service.selectedApartment=this.BuildingList[this.whatApFilter]
    this.numarulapartamentuluiselectat = this.BuildingList[this.whatApFilter].ApartmentNumb
    this.modaldetalii= true;  
  
    this.service.serviceRoDetalii=true;
    this.service.serviceEnDetalii=false;
  }
  
  close(){
    this.modaldetalii= false;  
  }
  
  seemoreapartments(){
      this.service.selectedfloor= this.BuildingFloorFilter
      this.service.selectedrooms= this.BuildingRoomsFilter
      this.service.selectedbuilding= this.BuildingNameFilter
      this.router.navigateByUrl('/locuinte' )
  }
  
  
  
  
  seetermeni(){
    this.router.navigateByUrl('/termeni-si-conditii');
  }
  
    footerlinks(){
      this.service.selectedfloor="0"
      this.service.selectedrooms= "1"
      this.service.selectedbuilding= "B"
      this.router.navigateByUrl('/locuinte' )
    }
    footerlinks1(){
      this.service.selectedfloor="0"
      this.service.selectedrooms= "2"
      this.service.selectedbuilding= "B"
      this.router.navigateByUrl('/locuinte' )
    }
    footerlinks2(){
      this.service.selectedfloor="1"
      this.service.selectedrooms= "2"
      this.service.selectedbuilding= "B"
      this.router.navigateByUrl('/locuinte' )
    }
    footerlinks3(){
      this.service.selectedfloor="3"
      this.service.selectedrooms= "3"
      this.service.selectedbuilding= "B"
      this.router.navigateByUrl('/locuinte' )
    }
    footerlinks4(){
      this.service.selectedfloor="4"
      this.service.selectedrooms= "5"
      this.service.selectedbuilding= "B"
      this.router.navigateByUrl('/locuinte' )
    }
  seehome(){
    this.router.navigateByUrl('/')
  }
  
  seemedia(){
    this.router.navigateByUrl('/mediapage')
    }
  seeBlog(){
    this.router.navigateByUrl('/blog')
  }
  seedezvoltator(){
    this.router.navigateByUrl('/dezvoltator')
    }
  
  seecontact(){
    this.router.navigateByUrl('/contact')
    }
  
  seelocatie(){
    this.router.navigateByUrl('/locatie')
    }
  
  seealege(){
    this.router.navigateByUrl('/locuinta')
    }
  
    lifestyle(){
      this.router.navigateByUrl('/lifestyle')
    }
  
    locatie(){
      this.router.navigateByUrl('/locatie')
    }
    festivallake(){
      this.router.navigateByUrl('/festivallake')
    }
    investairbnb(){
      window.open('/optiuni-de-investitie/ro', '_blank');
    }
  
    FilterFn5(){
  
      var BuildingNameFilter = this.BuildingNameFilter;
      var BuildingFloorFilter = this.BuildingFloorFilter;
      var BuildingRoomsFilter = this.BuildingRoomsFilter;
  
      this.BuildingList = this.BuildingListWithoutFilter.filter( (el: 
        { BuildingId:
        { toString: () => string; }; BuildingFloor: { toString: () => string; }; 
        BuildingName: { toString: () => string; }
        ApartmentRooms: { toString: () => string; };  }) =>{
         
  
        return el.ApartmentRooms.toString().toLowerCase().includes(
          BuildingRoomsFilter.toString().trim().toLowerCase()
        )
        && el.BuildingFloor.toString().toLowerCase().includes(
          BuildingFloorFilter.toString().trim().toLowerCase()
        )&&
          el.BuildingName.toString().toUpperCase().includes(
            BuildingNameFilter.toString().trim().toUpperCase()
          ) 
      });
   }
  
   /* burg
  
   //----------HEADER MOBILE BURGER----------//
  navSlide(){
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.mob-navbar');
    const navBar = document.querySelectorAll('.mob-navbar a');
  
    this.nav.classList.toggle('nav-active');
  
    navBar.forEach((link, index) => {
      if(this.link.style.animation){
        this.link.style.animation = '';
      } else {
      this.link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
      }
      });
  
    }er */
  
    navSlider(): void{
      const burger = document.querySelector('.burger');
      const nav = document.querySelector('.mob-navbar');
      const navBar = document.querySelectorAll<HTMLElement>('.mob-navbar a');
    //toggle nav regtangle
      nav!.classList.toggle('nav-active');
    //animate linksß
      navBar.forEach((link, index) => {
        if(link.style.animation){
          link.style.animation = '';
        } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.01}s`;
        }
      });
  
    //burger animation
    burger!.classList.toggle('toggle');
  
    }
  
  
    //ARROWS FILTRE PLANS
  
  
  
  slidetonextappartment(){
  
    this.whatApFilter++
    this.apSlideCounter ++
    if(this.BuildingList.length - 1 == this.apSlideCounter){
      document.getElementById("mob-plans-next")!.style.display = 'none';
      document.getElementById("mob-plans-next1")!.style.display = 'flex';
  
      document.getElementById("plans-next")!.style.display = 'none';
      document.getElementById("plans-next1")!.style.display = 'flex';
    }
    

    document.getElementById("mob-plans-prev")!.style.display = 'flex';
    document.getElementById("mob-plans-prev1")!.style.display = 'none';
  
    document.getElementById("plans-prev")!.style.display = 'flex';
    document.getElementById("plans-prev1")!.style.display = 'none';
  }
  
  slidetoprevappartment(){
  
    this.apSlideCounter --
    this.whatApFilter--
    document.getElementById("mob-plans-next")!.style.display = 'flex';
    document.getElementById("mob-plans-next1")!.style.display = 'none';
  
    document.getElementById("plans-next")!.style.display = 'flex';
    document.getElementById("plans-next1")!.style.display = 'none';
    if(this.apSlideCounter==0){
      document.getElementById("mob-plans-prev")!.style.display = 'none';
      document.getElementById("mob-plans-prev1")!.style.display = 'flex';
    
      document.getElementById("plans-prev")!.style.display = 'none';
      document.getElementById("plans-prev1")!.style.display = 'flex';
    }

  
  }
  //slider
  images = [
    {path: './../assets/media/carousel/homeslider_1.jpg'},
    {path: './../assets/media/carousel/homeslider_2.jpg'},
    {path: './../assets/media/carousel/homeslider_3.jpg'},
    {path: './../assets/media/carousel/homeslider_4.jpg'},
    {path: './../assets/media/carousel/homeslider_5.jpg'},
    {path: './../assets/media/carousel/homeslider_6.jpg'},
    {path: './../assets/media/carousel/homeslider_7.jpg'},
    {path: './../assets/media/carousel/homeslider_8.jpg'},
    {path: './../assets/media/carousel/homeslider_9.jpg'},
    {path: './../assets/media/carousel/homeslider_10.jpg'},
    {path: './../assets/media/carousel/homeslider_11.jpg'}
  
  ];
  
  imagesForSlider = [
    {path: './../assets/media/carousel/homeslider_1.jpg'},
    {path: './../assets/media/carousel/homeslider_2.jpg'},
    {path: './../assets/media/carousel/homeslider_3.jpg'},
    {path: './../assets/media/carousel/homeslider_4.jpg'},
    {path: './../assets/media/carousel/homeslider_5.jpg'},
    {path: './../assets/media/carousel/homeslider_6.jpg'},
    {path: './../assets/media/carousel/homeslider_7.jpg'},
    {path: './../assets/media/carousel/homeslider_8.jpg'},
    {path: './../assets/media/carousel/homeslider_9.jpg'},
    {path: './../assets/media/carousel/homeslider_10.jpg'},
    {path: './../assets/media/carousel/homeslider_11.jpg'}
  ];
  /*
  images = [
    {path: './../assets/media/carousel/Carousel1a.png'},
    {path: './../assets/media/carousel/carousel2.jpg'},
    {path: './../assets/media/carousel/carousel4.jpg'},
    {path: './../assets/media/carousel/carousel5.jpg'},
    {path: './../assets/media/carousel/carousel6.jpg'},
    {path: './../assets/media/carousel/carousel7.jpg'},
    {path: './../assets/media/carousel/carousel8.jpg'},
    {path: './../assets/media/carousel/carousel9.jpg'},
    {path: './../assets/media/carousel/carousel11.jpg'},
    {path: './../assets/media/carousel/carousel15.jpg'}
  
  ];
  
  imagesForSlider = [
    {path: './../assets/media/carousel/Carousel1a.png'},
    {path: './../assets/media/carousel/carousel2.jpg'},
    {path: './../assets/media/carousel/carousel4.jpg'},
    {path: './../assets/media/carousel/carousel5.jpg'},
    {path: './../assets/media/carousel/carousel6.jpg'},
    {path: './../assets/media/carousel/carousel7.jpg'},
    {path: './../assets/media/carousel/carousel8.jpg'},
    {path: './../assets/media/carousel/carousel9.jpg'},
    {path: './../assets/media/carousel/carousel11.jpg'},
    {path: './../assets/media/carousel/carousel15.jpg'}
  ];*/
  
  
  handleCarouselEvents(event:any) {
    console.log(event);
  }
    myNextFun() {
        console.log('--------------');
    }
  
    myNextFun1() {
      console.log('--------------');
  }
  
  changeRoEn(){
    this.service.selectEnRo=true;
    this.router.navigateByUrl('/en')
  }
  
  
  
  
    @HostListener('window:scroll', ['$event'])
    checkScroll(event: any) {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const elementTop = this.myDiv.nativeElement.getBoundingClientRect().top;
      const elementHeight = this.myDiv.nativeElement.offsetHeight;
    
  
      if (scrollPosition >= elementTop && scrollPosition < elementTop + elementHeight) {
        this.showMessage();
      }
    }
  
    showMessage() {
      console.log('You have reached the scroll point!');
      const fadeOutClass = 'fade-out';
      const fadeInClass = 'fade-in';
    
      // Set the initial building photo
      this.buildingFotoB = 'hoverSlider_B.png';
      this.buildingFotoClass = fadeInClass;
    
      setTimeout(() => {
        // Add fade-out class to the image
        this.buildingFotoClass = fadeOutClass;
    
        setTimeout(() => {
          // Change the building photo
          this.buildingFotoB = 'bloc-B1hover.jpg';
    
          // Add fade-in class to the image
          this.buildingFotoClass = fadeInClass;
        }, 1000);
      }, 5000);
    }


    filterByApartmentNumberAndBuilding(apartmentNumber: string, buildingName: string) {
      console.log("da")
        // Find the apartment in the list
        const selectedApartment = this.BuildingList.find((apartment: any) => 
          apartment.ApartmentNumb === apartmentNumber && apartment.BuildingName === buildingName);
      
        // If the apartment is found, set the details and open the modal
        if (selectedApartment) {
          this.service.selectedApartment = selectedApartment;
          console.log(selectedApartment)
          this.numarulapartamentuluiselectat = selectedApartment.ApartmentNumb;
          this.modaldetalii = true;
          this.service.serviceRoDetalii=true;
          this.service.serviceEnDetalii=false;
          $('#detailModal').modal('show');  // Make sure jQuery is properly loaded and working
        } else {
          console.error("Apartment not found");
        }
      }
      
    
    
    
  
  
  
  }