
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
	<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"/>	
    <title>Locuințe</title>
</head>


<body>
    <div class="header-bcground"></div>

    <header class="headerGrid">
        <div class="headerGrid-wrapper">
            <div class="header-titles">
                <img src="../../assets/media/logos/the_lake_logo_white.png" alt="The Lake home - Living Xperience logo" (click)="seehome()" >

                <div class="all-nav-elements">
                    <div class="contact-nav">

                        <div class="flexRow">
                            <a (click)="changeRoEn()">EN</a>
                        </div>
                        
                        <div class="header-phone"><span id="header-icon-phone" class="material-symbols-outlined">phone_iphone <a href="tel:+40721229999">+4 0721 229 999</a> </span></div>
                        <span class="vertical-line"></span>
                        <div class="header-mail"><span id="header-icon-mail" class="material-symbols-outlined">chat_bubble <a href="mailto:sales@thelake.ro">sales@thelake.ro</a></span></div>
                        <!-- Button trigger modal vizionare-->
                        <button class="header-button" type="button" data-toggle="modal" data-target="#brosuraModal">vezi&nbsp;broșura</button>  
                        
                        
                          <!-- Modal Vizionare-->
                          <div class="modal fade" id="vizionareModal" tabindex="-1" role="dialog" aria-labelledby="vizionareModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" aria-hidden="true">
                                    <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                            close
                                    </span>
                                </button>
                                <h5 class="modal-title" id="vizionareModalLabel">Programează o vizionare</h5>
                                </div>
                                <div class="modal-body">
                                    <div class="p">
                                        <p>Trimite-ne datele tale de contact pentru a putea fi preluate de către echipa noastră.</p>
                                    </div> 
                                    <form>                                        
                                      <input type="text" class="modal-form" [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" placeholder="Nume" required>
                                      <input type="email" class="modal-form" [ngModelOptions]="{standalone: true}" [(ngModel)]="Email" id="exampleFormControlInput1" placeholder="Email" required>
                                      <input type="tel" class="modal-form"[ngModelOptions]="{standalone: true}"  [(ngModel)]="Phone" placeholder="Telefon" required>
                                      <textarea id="modal-mesaj" class="modal-form" [ngModelOptions]="{standalone: true}" [(ngModel)]="Message" placeholder="Scrie-ne un mesaj" rows="3"></textarea>
                                      
                                      <div class="privacylink1"><input (click)="checked()" id="checkbox1" type="checkbox" required><label class="form-control1">Sunt de acord cu <a id="ppLink" (click)="seetermeni()" data-dismiss="modal">Consimțământul de prelucrare a datelor.</a></label></div>                                      
                                      <h6 *ngIf="acestCamp"> Acest câmp este obligatoriu</h6>
                                      
                                      <button class="deactive modal-button" type="button" (click)="sendFormOne()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                  </form>
                                </div>
                            </div>
                            </div>
                        </div>  
                        <!-- Modal Brosura-->
                        <div class="modal fade" id="brosuraModal" tabindex="-1" role="dialog" aria-labelledby="brosuraModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" aria-hidden="true">
                                    <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                            close
                                    </span>
                                </button>
                                <h5 class="modal-title" id="brosuraModalLabel">Vezi broșura</h5>
                                </div>
                                <div class="modal-body-brosura">
                                    <div class="p">
                                        <p>Completează formularul de mai jos pentru a descărca broșura The Lake Home.</p>
                                    </div> 
                                    <form>
                                      <input type="text" class="modal-form" placeholder="Nume"  [ngModelOptions]="{standalone: true}" [(ngModel)]="Name"required>
                                      <input type="email" class="modal-form" id="exampleFormControlInput1"  [ngModelOptions]="{standalone: true}" [(ngModel)]="Email" placeholder="Email" required>
                                      <input type="tel" class="modal-form" placeholder="Telefon"  [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                      <textarea id="modal-mesaj" class="modal-form" placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}" [(ngModel)]="Message" rows="3"></textarea>
                                      
                                      <div class="privacylink2"><input (click)="checked2()" id="checkbox2"  type="checkbox" required><label class="form-control2">Sunt de acord cu <a id="ppLink2"
                                          data-dismiss="modal" (click)="seetermeni()">Consimțământul de prelucrare a datelor.</a></label></div>                                        
                                      <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                      <button class="deactive modal-button-brosura" type="button" (click)="sendFormOne2()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                  </form>
                                </div>
                            </div>
                            </div>
                        </div>   

                        <!-- XMAS promo Modal-->
                        <div class="modal fade" id="XmasModal" tabindex="-1" role="dialog"
                            aria-labelledby="XmasModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content XmasContent">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            aria-hidden="true">
                                            <span aria-hidden="true" class="close-icon material-symbols-outlined">
                                                close
                                            </span>
                                        </button>
                                        <h5 class="modal-title XmasTitle" id="OfertaModalLabel">HO, HO,
                                            <span>HOME</span></h5>
                                    </div>
                                    <div class="modal-body-oferta">
                                        <div class="p">

                                            <p> <span class="xmasSubTitle">Serbează <strong>Crăciunul</strong> acasă și
                                                    fă loc
                                                    sub brad pentru <strong>un cadou în plus.</strong> </span><br><br>

                                                Achiziționează unul din ultimele apartamente disponibile
                                                în blocul C sau achită <strong>un avans de 50% </strong>pentru un
                                                apartament în blocul B1 și primești cadou de casă nouă
                                                un <strong>robot de bucătărie Thermomix TM6.</strong> <br><br>

                                                În plus, îți oferim un<strong> avantaj client</strong> cuprins între
                                                <strong>2,500 și
                                                    38,000 euro </strong>în baza avansului din prețul de listă pentru
                                                noua ta locuință în blocurile B1 și B2.<br><br>

                                                Lasă-ne datele tale și programează o vizionare.</p>
                                        </div>
                                        <form>
                                            <input type="text" class="modal-form" placeholder="Nume"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required>
                                            <input type="email" class="modal-form" id="exampleFormControlInput1"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Email"
                                                placeholder="Email" required>
                                            <input type="tel" class="modal-form" placeholder="Telefon"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                                            <textarea id="modal-mesaj" class="modal-form"
                                                placeholder="Scrie-ne un mesaj" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="Message" rows="3"></textarea>

                                                <div class="privacylink2"><input (click)="checked4()" id="checkbox2"
                                                    type="checkbox" required><label class="form-control2">Sunt de acord
                                                    cu <a id="ppLink2" data-dismiss="modal"
                                                        (click)="seetermeni()">Consimțământul de prelucrare a
                                                        datelor.</a></label></div>
                                            <h6 *ngIf="acestCamp1">Acest câmp este obligatoriu</h6>
                                            <button class="deactive modal-button-oferta" type="button"
                                                (click)="sendFormOferta()" data-dismiss="modal">TRIMITE MESAJUL</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="logo-navbar">

                        <div class="mob-navbar">
                            <div class="distance-top-navbar">
                                <a class="blue-border" (click)="seelifestyle()">LIFESTYLE</a>
                                <a class="blue-border" (click)="footerlinks1()">ALEGE-ȚI&nbsp;REZIDENȚA</a>
                                <a class="blue-border" (click)="seelocatie()">LOCAȚIE</a>
                                <a class="blue-border" href="https://thelake.ro/galerie/">GALERIE</a>
                                <a class="blue-border" (click)="seemedia()">MEDIA</a>
                                <a class="blue-border" (click)="seedezvoltator()">DEZVOLTATOR</a>
                                <a (click)="seecontact()">CONTACT</a>
                            </div>

                        </div>
                        <div class="burger" (click)="navSlider()">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>

                        <div class="navbar">
                            <a (click)="seelifestyle()">LIFESTYLE</a>
                            <a (click)="footerlinks1()">ALEGE-ȚI&nbsp;REZIDENȚA</a>
                            <a (click)="seelocatie()">LOCAȚIE</a>
                            <a href="https://thelake.ro/galerie/">GALERIE</a>
                            <a (click)="seemedia()">MEDIA</a>
                            <a (click)="seedezvoltator()">DEZVOLTATOR</a>
                            <a (click)="seecontact()">CONTACT</a>
                        </div>
                    </div>
                </div>
                    <div class="mob-page-title">
                        <h1>Apartament</h1>
                    </div>    
            </div>

            <div class="header-slider">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false">
                    <div class="carousel-inner" id="headerSlider-inner">
                      <div class="carousel-item active">
                        <img class="d-block w-100" src="../../assets/media/headerSliders/locuinte_1.png" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100" src="../../assets/media/headerSliders/locuinte_3.jpg" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100 testImg" src="../../assets/media/headerSliders/locuinte_2.jpg" alt="">
                      </div>
                      <div class="carousel-item">
                        <img class="d-block w-100" src="../../assets/media/headerSliders/locuinte_4.jpeg" alt="">
                      </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span id="bloc-prev" class="material-symbols-outlined">arrow_circle_left</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span id="bloc-next" class="material-symbols-outlined">arrow_circle_right</span>
                    </a>
                </div>
            </div>         


            <!-- call to action buttons -->
            <div class="cta-btns">
                <div class="cta-visionare" type="button" data-toggle="modal" data-target="#vizionareModal">
                    <span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span><a>programează o vizionare</a>
                </div>
                <div class="cta-brosura" type="button" data-toggle="modal" data-target="#brosuraModal">
                    <img src="../../assets/media/icons/brosuraIcon.svg" alt="download brosura icon"><a>Vezi broșura</a>
                </div>
                
            </div>
            

            <div class="sticky-btn" type="button" data-toggle="modal" data-target="#vizionareModal"><span id="cta-viz-icon" class="material-symbols-outlined">chat_bubble</span><h5>programează <br> o vizionare</h5></div>


            <!--xmasPromo
            <div class="XMAS-sticky-btn" type="button" data-toggle="modal" data-target="#XmasModal"><img
                src="../../assets/media/icons/favpng_vector-graphics-gift-clip-art-box.png" alt="">
        </div>-->
        </div>
        <div class="page-title"><h1>Apartament</h1></div>
    </header>

    <div class="mob-header-slider">
        <div class="header-slider-bcground"></div>
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel"> 
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner" id="mobSlider-inner">
              <div class="carousel-item active">
                <img class="d-block w-100" src="../../assets/media/headerSliders/locuinte_1.png" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../assets/media/headerSliders/locuinte_3.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../assets/media/headerSliders/locuinte_2.jpg" alt="">
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src="../../assets/media/headerSliders/locuinte_4.jpeg" alt="">
              </div>
            </div>
          </div>
    </div>

    <div class="header-database">
        <div class="dataBase-wrapper">
            <h5>Listează planuri</h5>

            <div class="db-etaj">
                <div class="etaj-cicle"><span id="db-etaj-arrow" class="material-symbols-outlined">chevron_right</span></div>
                    <div class="etaj-names" id="moreetaj">
                        <h6 class="etaj-arrow">Etaj</h6>
                        <ul class="nrEtaj">
                            <li  (click)="makeEtajParter()">0</li>
                            <li (click)="makeEtaj1()">1</li>
                            <li (click)="makeEtaj2()">2</li>
                            <li (click)="makeEtaj3()">3</li>
                            <li (click)="makeEtaj4()">4</li>
                        </ul>
                    </div>    
            </div>
            <div class="db-camere">
                <div class="camere-cicle"><span id="db-camere-arrow" class="material-symbols-outlined">chevron_right</span>
                </div>
                <div class="camere-names" id="moreCamere">
                    <h6 class="camere-arrow">nr. camere </h6>
                    <ul class="nrCamere">
                        <li class="deactive" (click)="makerooms1()">1</li>
                        <li class="deactive" (click)="makerooms2()">2</li>
                        <li class="deactive" (click)="makerooms3()">3</li>
                        <li class="deactive" (click)="makerooms4()">4</li>
                        <li class="deactive" (click)="makerooms5()">5</li>
                    </ul>
                </div>   
            </div>
            <div class="db-button" (click)="seemoreapartments()"><button>VEZI LISTA DE PLANURI</button></div>
        </div>  
    </div>

    <section class="plans-details-wrapper">
        <h2>{{ApartmentRooms}} {{camere}}</h2>
        <div class="plans-details">
            <div class="plans-img">
                <img src={{hollowPhoto}} alt="">
            </div>
            <div class="detalii">
                <h6>{{ApartmentPrice.slice(0, -3)}},{{ApartmentPrice.slice(-3)}}<span>€+TVA </span></h6>
                <h5>PRET</h5>
                <h4></h4>

                <div class="bloc-etaj">
                    <div class="Bloc">
                        <h5>Bloc {{BuildingName}}</h5>
                        <p>nr. {{ApartmentNumb}}</p>
                    </div>
                    <div class="etaj">
                        <h5>Etaj</h5>
                        <p>{{hollowfloor}}</p>
                    </div>
                </div>

                <div class="detalii-plan">
                    <ol>
                        <li>Living <p>{{SurfaceRoom}}m&#178;</p></li>
                        <li>Dormitor <p>{{SurfaceDormitor}}m&#178;</p></li>
                        <li>Baie <p>{{SurfaceBaie}}m&#178;</p></li>
                        <li>Hol <p>{{SurfaceHol}}m&#178;</p></li>
                        <li>Terasa <p>{{ApartmentBalconSurface}}m&#178;</p></li>
                    </ol>
                </div>

                <div class="sUtila-location">
                    <div class="sUtila">
                        <h5>Suprafața</h5>
                        <p>{{ApartmentSurface}}m&#178;</p>
                    </div>
                    <div class="location">
                        <h5>Orientare</h5>
                        <p>{{ApartmentOrentation}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="blu-div-desk"><div class="blu-div"></div></div>
        <!--  
        <div class="desc-plan"><button >Descarca Plan</button></div>
         -->
    </section>

    <section class="page-content-wrapper">
        <div class="page-content"> 
            <div id="content-7">
                <h3>Esența unui ambient bine gândit stă în finisări de calitate
                </h3>
            </div>

            <img id="content-9" src="../../assets/media/carousel/carousel5.jpg" alt="Rendering of The Lake Home Spa and Venue">

            <h4 id="content-8">Finisajele de înaltă calitate reprezintă garanția unei vieți lipsite de griji, la&nbsp;tine&nbsp;acasă.</h4>
    
            <div class="division"></div>

            <div id="content-10">
                <p>Toate apartamentele vor fi predate cu ușă metalică de maximă siguranță, încălzire în pardoseală, centrală termică în condensație VISSMANN, geamuri termopan cu profil de aluminiu SCHUCO cu 3 foi de sticlă, zugrăvit la alb, instalații sanitare, electrice și gaz cu contorizare separată.</p>
            </div>
        </div>

        <div class="gray-bckg-wrapper"><div class="gray-bckg"></div></div>

    </section>

    <section class="allpages-bottom">

        <section class="investitia-wrapper" id="INVESTEȘTE">

            <div class="inv-wrapper-wrapper">      
                <div class="investitia">
                    <h2>Care este investiția ideală pentru&nbsp;tine</h2>
                    <div class="investitia-blueBar"></div>
                    <p>Investițiile imobiliare inteligente au fost, sunt și vor fi unul dintre cele mai bune moduri de transport al banilor în timp</p>
                </div>	
                <div class="calculator">
    
                    <div class="bigdiv">
                        <h3>Calculator investiție</h3>
                        <p class="suma">Sumă investită</p>
                        <input class="calcone" id="first" min="7000" placeholder="suma în €" type="number">
                        <p class="termen">Termen <br></p>
                        <div class="labelandimput">
                            <div class="labelandimput2">
                                <input checked="checked" type="radio" id="age2" name="age" value="15">
                                <label class="tag" for="age2">18&nbsp;luni</label>
                            </div>
                                <div class="labelandimput2">
                                <input  type="radio" id="age3" name="age" value="35">
                            <label class="tag" for="age3">36&nbsp;luni</label> 
                            </div>
                            <div class="labelandimput2">
                                <input  type="radio" id="age4" name="age" value="55">
                                <label class="tag" for="age4">54&nbsp;luni</label>
                            </div>
                        </div>
                        <button onclick="add()" class="calc-btn">CALCULEAZĂ-ȚI INVESTITIA</button>
                    </div>
                    <div class="rectangle">
                        <p class="procentul">Profit la termen&nbsp;% 
                        <input class="firstresult" id="answer3" disabled="disabled" placeholder="_ %"></p>
                        
                        <p class="dobanda">Profit la termen&nbsp;€
                        <input class="firstresult" id="answer2" disabled="disabled" placeholder="_ €"></p>   
            
                        <p class="return">Sumă returnată la termen&nbsp;€
                        <input class="firstresult" id="answer" disabled="disabled" placeholder="_ €"></p>
                    </div>
            
                </div>
            </div>    
        </section>

        <section class="testemonials">
            <h2>Testimoniale</h2>
  
            <div id="carouselExampleControls3" class="carousel slide" data-ride="carousel" data-interval="false">
              <div class="carousel-inner" id="testm-inner">
                <div class="carousel-item active">
                    <p class="testimonial-text">Am fost atras de caracteristicile acestui proiect imobiliar, faptul că există multă grijă față de locatari, le sunt oferite multiple variante de recreere. Potențialul acestui proiect este imens, de aceea am ales să achiziționez un apartament cu scop de investiție în primul bloc.</p>
                    <p class="name-testimonial">Ștefan Savu</p>
                    <p class="age-city">22 ANI - SIBIU</p>   
                </div>
                <div class="carousel-item">
                    <p class="testimonial-text">Consider că este o alegere perfectă pentru mine, având în vedere toate avantajele. Îmi place priveliștea, munții se văd frumos în depărtare și lacul este fain de aproape. Este suprinzător de simplu să ajungi în diverse puncte din oraș, conectivitatea e bună.</p>
                    <p class="name-testimonial">George Vesa</p>
                    <p class="age-city">24 ANI - SIBIU</p>
                </div>
                <div class="carousel-item">
                    <p class="testimonial-text">Construcția este realizată la o calitate superioară față de alte blocuri, finisajele exterioare și interioare sunt de apreciat. Îmi place spațiul extins, faptul că apartamentul este luminos, și că totul te face să te simți bine. Până și casa scărilor este minuțios realizată, e senzațională.</p>
                    <p class="name-testimonial">Răzvan Staroste</p>
                    <p class="age-city">38 ANI - SIBIU</p>
                </div>
              </div>
              <div class="slider-controls-Testm">
                  <span id="testm-slide-prev" class="material-symbols-outlined left-btn-testm" href="#carouselExampleControls3" role="button" data-slide="prev">arrow_circle_left</span>
                  <span id="testm-slide-next" class="material-symbols-outlined right-btn-testm" href="#carouselExampleControls3" role="button" data-slide="next">arrow_circle_right</span>
              </div>
            </div>
               
            <div class="bg-img-testm"></div>
        </section>
    </section>

    <footer>
        <div class="footer-wrapper">
            <div class="footer-logo">
                <p>Proiect dezvoltat de:</p>
                <img src="../../assets/media/logos/sigla_negative-02.png" alt="Novarion Logo" onclick="NewTabNovarion()">
            </div>
            <div class="footer1">
                <h5>Birou vânzări:</h5>
                <p class="footer1-bold">The Lake Home</p>
                <a href="https://goo.gl/maps/u8YrSsReceFjCgq37" target=”_blank”><p>Strada Tractorului, nr. 12- 28,<br>Sibiu, România</p></a> 
                <div>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br>
                    <span>T.</span><a href="tel:+40721229999">+40 721 229 999</a> <br><br>
                    <span>T.</span><a href="mailto:sales@thelake.ro">sales@thelake.ro</a>
                </div>
                <img src="../../assets/media/pattern/brand_icon.svg" alt="">
            </div>
            <div class="footer2">
                <h5>Alege-ți rezidența</h5>
                <div class="footer2-rezidentie">
                    <div class="rezidentie1">
                        <a (click)="footerlinks1()">APARTAMENT STUDIO DUBLU</a> <br>
                        <a (click)="footerlinks2()">APARTAMENT CU 2 CAMERE</a><br>
                        <a (click)="footerlinks3()">APARTAMENT CU 3 CAMERE</a><br>
                        <a (click)="footerlinks4()">APARTAMENT CU 5 CAMERE</a><br>

                    </div>
                    <!-- 
                    <div class="rezidentie2">
                        <a href="">VILA TIP A</a><br>
                        <a href="">VILA TIP B</a><br>
                        <a href="">VILA TIP C</a><br>
                    </div>
                    -->
                </div>
                <div class="footer2-pages">
                    <div class="pages1">
                        <a (click)="seehome()">HOME</a> <br>
                        <a (click)="seelifestyle()">LIFESTYLE</a><br>
                        <a (click)="seelocatie()">LOCAȚIE</a><br>
                    </div>
                    <div class="pages2">
                        <a href="https://locuintamea.novarion.ro/">INVESTESTE</a><br>
                        <a (click)="seemedia()">MEDIA</a><br>
                        <a (click)="seedezvoltator()">DEZVOLTATOR</a><br>
                    </div>
                </div>
                <div class="footer2-socialMedia">
                    <img src="../../assets/media/icons/facebook_logo_icon.svg" alt="Facebook link" onclick="NewTabFb()">
                    <img src="../../assets/media/icons/linkedin_logo_icon.svg" alt="LinkedIn link" onclick="NewTabLi()">
                    <img src="../../assets/media/icons/instagram_logo_icon.svg" alt="Instagram link" onclick="NewTabIg()">
                    <img src="../../assets/media/icons/youtube_icon.svg" alt="Youtube link" onclick="NewTabYt()">
                </div>
                <div class="footer2-end">
                    <a (click)="seetermeni()">Politica de confidențialitate | Cookies</a>
                    <p>Brand & Web Design : <a href="https://www.mugurelfrincu.ro/" target="_blank"> Mugurel Frîncu</a></p>

                </div>
            </div>
            <div class="footer3">
                <h4>Te contactăm noi</h4>
                <p>Completează formularul de mai jos pentru a primi în cel mai scurt timp un răspuns din partea echipei de vânzări</p>
                <input type="text" placeholder="Nume"[ngModelOptions]="{standalone: true}" [(ngModel)]="Name" required> 
                <input type="email" placeholder="Email"[ngModelOptions]="{standalone: true}" [(ngModel)]="Email" required>
                <input type="tel" placeholder="Telefon"[ngModelOptions]="{standalone: true}" [(ngModel)]="Phone" required>
                <textarea id="mesaj" placeholder="Scrie-ne un mesaj"[ngModelOptions]="{standalone: true}" [(ngModel)]="Message"></textarea>
                <div class="privacylink3"><input (click)="checked3()" id="checkbox3" type="checkbox" required  ><label class="form-control3">Sunt de acord cu <a id="ppLink3" (click)="seetermeni()"> Consimțământul de prelucrare a datelor.</a></label></div>
                <h6 *ngIf="acestCamp2">Acest câmp este obligatoriu</h6>
                <button class="deactive" type="button" (click)="sendFormOne3()">TRIMITE MESAJUL</button>
            </div>
        </div>  
    </footer>

      
    





<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
<script src="apartament.scripts.js"></script>    
</body>

